import React from "react";
import explore_download_icon from "../../assets/section_new_images/exploremore/explore_download_icon.png";

const SingleInputContainer = ({ labelText, type }) => {
  return (
    <div className="d-flex flex-column pt-3">
      <label className="mb-0">
        {labelText}&nbsp;<span className="star">*</span>
      </label>
      <input required type={type} />
    </div>
  );
};

const Download = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center py-5">
      <div className="text-center w-75">
        <span className="headerText px-md-5">
          World-class innovative programs in a variety of fields to cater to the
          inquisitive minds
        </span>
      </div>
      <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-around pt-5">
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
          <img
            className="d-none d-sm-block img-fluid"
            src={explore_download_icon}
            alt=""
            loading="lazy"
            style={{ objectFit: "contain", maxWidth: "300px" }}
          />
        </div>
        <div className="col-12 col-md-6 download_prospectus">
          <span>Download&nbsp;Prospectus</span>
          <form className="d-flex flex-column right_input_container">
            <SingleInputContainer labelText="Name" type="text" />
            <SingleInputContainer labelText="Email" type="email" />
            <SingleInputContainer labelText="Phone" type="number" />
            <button type="submit" className="py-2 mt-3">
              Download
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Download;
