import React from "react";

const ColorfulBox = ({item}) => {
  return (
    <div
      className="colorfulContainer"
      style={{ backgroundColor: item.bgColor }}
    >
      <div className="text-center">
        <span className="colorfulHeaderTxt" style={{ color: item.headerColor }}>
          {item.headerTxt}
        </span>
      </div>
      <div className="row">
        <span className="colorfulContentTxt" style={{ color: item.txtColor }}>
          {item.contentTxt}
        </span>
      </div>
    </div>
  );
};

export default ColorfulBox;
