import React from "react";
import SiteLayout from "@layouts/site-layout";
import Download from "@components/ExploreMore/Download";
import WhyStepSkills from "@components/ExploreMore/WhyStepSkills";
import OurPedagogy from "@components/ExploreMore/OurPedagogy";
import OurPrograms from "@components/ExploreMore/OurPrograms";
import OurPartners from "@components/ExploreMore/OurPartners";

import "../../assets/section_new_styles/explore_more.scss";
import OurDistinguishAlumni from "../../components/ExploreMore/OurDistinguishAlumni";

const index = () => {
  return (
    <SiteLayout>
      <div className="home_middle_sec top_margin_comn px-3 container">
        <Download />
        <WhyStepSkills />
        <OurPrograms />
        <OurPedagogy />
        <OurDistinguishAlumni />
        <OurPartners />
      </div>
    </SiteLayout>
  );
};

export default index;
