import React from "react";
import pic1 from "../../assets/section_new_images/image 96.png";
import pic2 from "../../assets/section_new_images/image 97.png";
import pic3 from "../../assets/section_new_images/image 98.png";
import pic4 from "../../assets/section_new_images/image 99.png";
import pic5 from "../../assets/section_new_images/image 100.png";
import pic6 from "../../assets/section_new_images/image 101.png";
import pic7 from "../../assets/section_new_images/image 102.png";
import pic8 from "../../assets/section_new_images/image 103.png";
import pic9 from "../../assets/section_new_images/image 104.png";
import pic10 from "../../assets/section_new_images/image 105.png";
import pic11 from "../../assets/section_new_images/image 106.png";
import pic12 from "../../assets/section_new_images/image 107.png";

const data = [
  {
    id: 0,
    image: pic1,
    text:
      "Task based framework with adequate hands-on application of the learnings",
  },
  {
    id: 1,
    image: pic2,
    text: "Masterclass with Industry expert",
  },
  {
    id: 2,
    image: pic3,
    text: "Live interactive programs",
  },
  {
    id: 3,
    image: pic4,
    text: "Real-time dashboards & leaderboards for progress mapping",
  },
  {
    id: 4,
    image: pic5,
    text: "Gamified Self-learning material",
  },
  {
    id: 5,
    image: pic6,
    text: "Shark-Tank like Presentations",
  },
  {
    id: 6,
    image: pic7,
    text: "In class assessments to measure engagement and knowledge transfer",
  },
  {
    id: 7,
    image: pic8,
    text: "Action learning projects",
  },
  {
    id: 8,
    image: pic9,
    text: "Real-time reporting and analysis",
  },
  {
    id: 9,
    image: pic10,
    text: "Renowned industry professionals to conduct trainings",
  },
  {
    id: 10,
    image: pic11,
    text: "Small batch size for dedicated attention",
  },
  {
    id: 11,
    image: pic12,
    text: "Career Counseling & Mentorship",
  },
];

const OurPedagogy = () => {
  return (
    <div className="py-2 py-md-5">
      <div className="headerText text-center py-3 py-md-5">Our Pedagogy</div>
      <div className="row">
        {data.map((item) => (
          <div className="col-12 col-md-6 col-lg-4  mb-2">
            <div
              className="d-flex align-items-start"
              style={{
                backgroundColor: "#FDEFE7",
                color: "#FF854B",
                borderRadius: "20px",
                padding: "24px",
              }}
            >
              <div className="row">
                <div className="col-2">
                  <img
                    src={item.image}
                    alt="image"
                    style={{ height: "32px", width: "32px" }}
                  />
                </div>
                <div className="col-10">
                  <span>{item.text}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurPedagogy;
