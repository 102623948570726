import React from "react";
import p_1 from "../../assets/section_new_images/exploremore/p_1.png";
import p_2 from "../../assets/section_new_images/exploremore/p_2.png";
import p_3 from "../../assets/section_new_images/exploremore/p_3.png";
import p_4 from "../../assets/section_new_images/exploremore/p_4.png";
import p_5 from "../../assets/section_new_images/design.webp";
import p_6 from "../../assets/section_new_images/product.webp";
import p_7 from "../../assets/section_new_images/industrial.webp";
import p_8 from "../../assets/section_new_images/communication.webp";

import { Link } from "gatsby";

const data = [
  {
    id: 0,
    bgColor: "#FAE0F3",
    image: p_1,
    title: "Web & Mobile App Development",
    subtitle:
      "Design, develop and deploy web and mobile application from scratch.",
    pageto: "/exploremore/webandapplication",
  },
  {
    id: 1,
    bgColor: "#FBFDD1",
    image: p_2,
    title: "Circular design & Sustainability",
    subtitle: "Design and promote a circular economy.",
    pageto: "/exploremore/circulardesign",
  },
  {
    id: 2,
    bgColor: "#D0FDE0",
    image: p_3,
    title: "Game Design",
    subtitle: "Design engaging, immersive, and entertaining games.",
    pageto: "/exploremore/gamedesign",
  },
  {
    id: 3,
    bgColor: "#DFD4FF",
    image: p_4,
    title: "Storytelling with data analytics",
    subtitle: "Transform complex data sets into compelling narratives.",
    pageto: "/exploremore/storytelling",
  },
  {
    id: 4,
    bgColor: "#DFD4FF",
    image: p_5,
    title: "Design Thinking",
    subtitle:
      "Use the principles of design thinking to create user oriented products.",
    pageto: "/exploremore/designthinking",
  },
  {
    id: 5,
    bgColor: "#D0FDE0",
    image: p_6,
    title: "Product Design",
    subtitle:
      "Create 3D models of products, design product components and assemblies.",
    pageto: "/exploremore/productdesign",
  },
  {
    id: 6,
    bgColor: "#FAE0F3",
    image: p_7,
    title: "Industrial Safety",
    subtitle:
      "Create a safety culture that prioritizes the safety of the organization & employees",
    pageto: "/exploremore/industrial",
  },
  {
    id: 7,
    bgColor: "#FBFDD1",
    image: p_8,
    title: "Communication Design",
    subtitle: "Effectively communicates messages, ideas and concepts.",
    pageto: "/exploremore/communication",
  },
];

const OurPrograms = () => {
  return (
    <div className="py-5">
      <div className="headerText text-center py-3">Our Programs</div>
      <div className="our_program_cardContainer" style={{ gap: "1rem" }}>
        {data.map((item) => (
          <div
            className="d-flex flex-column align-items-center justify-content-between p-4 text-center our_program_cardContainer_singleCard"
            style={{ backgroundColor: item.bgColor }}
          >
            <img src={item.image} alt="" style={{ width: "100px" }} />
            <p className="mb-0">{item.title}</p>
            <span>{item.subtitle}</span>
            <Link className="py-2 px-4 text-white" to={item.pageto}>
              Know More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurPrograms;
