import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import parag_agarwal from "../../assets/section_new_images/exploremore/parag_agarwal.webp";
import yashodhan from "../../assets/section_new_images/exploremore/yashodhan.webp";
import harsh from "../../assets/section_new_images/exploremore/harsh.webp";

import '../../assets/section_new_styles/new_testimonial.scss'

const data = [
  {
    id: 0,
    bgColor: "#FBFDD1",
    boxShadowColor: " #B7BD3C",
    image: parag_agarwal,
    name: "Parag Agarwal",
    designation: "Ex-CEO, Twitter",
    dialogue: `Parag Agrawal is an Indian-American software engineer and businessman who served as CEO of Twitter from November 2021 to October 2022. Agrawal completed his education in computer science and engineering at IIT Bombay and Stanford University. He joined Twitter as a software engineer in 2011, rising to become the company's chief technology officer and head of Project Bluesky. Agrawal succeeded Jack Dorsey as CEO of Twitter in November 2021, overseeing several key developments, including the launch of the "Super Follows" subscription service and the integration of Bitcoin payments into the platform. Despite his short tenure as CEO, Agrawal is highly regarded for his expertise in artificial intelligence and machine learning and his contributions to Twitter's technological development.`,
  },
  {
    id: 1,
    bgColor: "#FAE0F3",
    boxShadowColor: "#B45AD3",
    image: yashodhan,
    name: "Yashodhan Kanoria",
    designation: "Core Programmer for UBER",
    dialogue: `Yashodhan Kanoria is an Associate Professor of Business at Columbia Business School with research interests in probability theory, optimization, and game theory. He obtained a BTech from IIT Bombay in 2007, a PhD in Electrical Engineering from Stanford in 2012, and spent a year at Microsoft Research New England during 2012-13 as a Schramm postdoctoral fellow. He has published numerous papers in top-tier academic journals and received several awards, including the INFORMS Optimization Society Prize for Young Researchers. Kanoria advises companies on operations optimization and data-driven strategies for growth. He is highly regarded as a scholar and educator, contributing significantly to our understanding of decision-making under uncertainty and risk. His work has helped shape how businesses approach risk management and decision-making, making him an influential figure in the academic community.`
  },
  {
    id: 2,
    bgColor: "#D0FDE0",
    boxShadowColor: "#28AF58",
    image: harsh,
    name: "Harsh Pareek",
    designation: "Research Scientist at Facebook",
    dialogue: `Harsh Pareek is an ex-Meta Research Scientist and Machine Learning engineer with a strong educational background, including a B.Tech in Computer Science from IIT Bombay and a PhD from UT Austin. He specializes in developing end-to-end ML solutions, with a proven track record of tackling complex problems. Harsh is a versatile generalist with a keen interest in a wide range of subjects, including physics, math, psychology, philosophy, and history, and is a former participant in the International Physics Olympiad, where he won a silver medal. His broad expertise, deep technical knowledge, and passion for learning make him a valuable asset to any team.`
  },
];

const Card = ({ item }) => {
  return (
    <div
      className="alumni_single_card"
      style={{
        boxShadow: `0px 10px ${item.boxShadowColor}`,
        backgroundColor: item.bgColor,
        gap: '1rem',
      }}
    >
      <div className='image_container'>
        <img src={item.image} alt='' />
      </div>
      <h1 className="mb-0">{item.name}</h1>
      <p className="mb-0">{item.designation}</p>
      <div className="read_only_text">
        <p className="mb-0">{item.dialogue}</p>
        <div className="read_only_content">
          <ReadMore subtitle={item.dialogue} />
        </div>
      </div>
    </div>
  );
};

const ReadMore = ({ subtitle }) => {
  const text = subtitle;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
  };
  return (
      <p className='subtitle' style={{ display: 'inline', width: "100%" }}>
          {isReadMore ? text.slice(0, 150) : text}
          <span onClick={toggleReadMore} className="read-or-hide font-weight-bold">
              {isReadMore ? "...read more" : " show less"}
          </span>
      </p>
  );
};


const OurDistinguishAlumni = () => {
  return (
    <div className="pt-5">
      <div className="headerText text-center py-3">
        Our Distinguished Alumni
      </div>
      <div className="row justify-content-center mt-3">
        <span className="contentText text-center">
          We have sent over 10,000+ student to IITs, PACE continues its legacy
          after their formal education. We are glad to inform you that some of
          our students are serving some of the best companies in the world
        </span>
      </div>
      <div className="py-2 py-md-5">
        <Slider
          {...{
            dots: true,
            infinite: true,
            speed: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 768, // Mobile breakpoint
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          }}
        >
          {data.map((item) => (
            <div key={item.id}>
              <Card item={item} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurDistinguishAlumni;
