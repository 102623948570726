import React from "react";

import partner1 from "../../assets/section_new_images/image 109.png";
import partner2 from "../../assets/section_new_images/image 110.png";
import ColorfulBox from "@components/ExploreMore/customComponents/ColorfulBox";

const OurPartners = () => {
  return (
    <div className="container pt-5">
      <div className="row justify-content-center">
        <span className="headerText">Our Partners</span>
      </div>
      <div className="row justify-content-center mt-3">
        <span className="contentText text-center">
          We take a great responsibility in choosing our partners and make sure
          together we deliver the best quality results for our students.Here are
          a few collaborations we have formed to ensure children have a pleasant
          and easy learning experience. Genius Teachers, Genius Performance, One
          platform for your every educational need!
        </span>
      </div>
      <div className="row justify-content-center py-5">
        <img
          src={partner1}
          alt="img"
          style={{ height: "365px", width: "337px" }}
        />
        <img
          src={partner2}
          alt="img"
          style={{ height: "365px", width: "337px" }}
        />
      </div>
      {/* <div className="d-flex">
        <ColorfulBox
          headerTxt={"3 Months"}
          contentTxt={"Under 10 hours study of study a week."}
          txtColor={"#7D7D7D"}
          headerColor={"#545706"}
          bgColor={"#FBFDD1"}
        />
        <ColorfulBox
          headerTxt={"English"}
          contentTxt={"Under 10 hours study of study a week."}
          txtColor={"#7D7D7D"}
          headerColor={"#0A662A"}
          bgColor={"#D0FDE0"}
        />
        <ColorfulBox
          headerTxt={"Beginner"}
          contentTxt={"Under 10 hours study of study a week."}
          txtColor={"#7D7D7D"}
          headerColor={"#6F0755"}
          bgColor={"#FAE0F3"}
        />
        <ColorfulBox
          headerTxt={"100% Self-Paced"}
          contentTxt={"Under 10 hours study of study a week."}
          txtColor={"#7D7D7D"}
          headerColor={"#2A0D7E"}
          bgColor={"#DFD4FF"}
        />
      </div> */}
    </div>
  );
};

export default OurPartners;
